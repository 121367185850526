import React from "react"
import { Row, Col, Tabs, Tab } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import { Section, SectionHeading } from "../../UI/Common"
import BrandItem from "./BrandItem"
import CategoryItem from "./CategoryItem"
import { sectionCls } from "./BrowesTabs.module.scss"

const BrowesTabs = ({ sectionHeading, brands, categories, config, extraBrandTiles }) => {

  //Brands can contain a brand: null value & logo: null
  let filteredBrands = brands && brands.filter(e => Boolean(e) && e.brand && e.brand.extendedBrandInfo.logo)
  if (Array.isArray(filteredBrands)) {
    filteredBrands.forEach(item => {
      if(item && item.brand && !item.brand.slug.includes('/brands/')) {
        item.brand.slug = `/brands/${item.brand.slug}`
      }
    })
  }
  let brandsArray = [];
  let customTiles = extraBrandTiles && extraBrandTiles.map(tile => {
    return {brand:{
      fieldGroupName: tile.fieldGroupName,
      extendedBrandInfo: {
        logo: tile.overlayedLogo,
        smallImage: tile.tileBackgroundImage,
      },
      id: uuidv4(),
      name: tile.tileLink.title,
      slug: tile.tileLink
    }
  }})

  if(filteredBrands.length){
    brandsArray = filteredBrands.length < 8 ? [...filteredBrands,...customTiles] : filteredBrands
  }else {
    brandsArray = customTiles
  }
  return (
    <Section className={sectionCls} padding={config?.padding}>
      <div className="w-100">
        <SectionHeading alignment="center" text={sectionHeading} />
        <Tabs className="mt-3" id="browes-by">
          <Tab eventKey="brands-tab" title="Shop by Brands">
            <Row className="no-gutters">
              {brandsArray &&
                brandsArray.map(
                  item =>
                    item.brand !== null && (
                      <Col xs={6} lg={3} key={uuidv4()}>
                        <BrandItem brand={item.brand}/>
                      </Col>
                    )
                )}
            </Row>
          </Tab>
          <Tab eventKey={"tab2"} title={"Shop by Category"}>
            <Row className="no-gutters">
              {categories.map(item => (
                <Col xs={6} lg={3} key={uuidv4()}>
                  <CategoryItem category={item.category} />
                </Col>
              ))}
            </Row>
          </Tab>
        </Tabs>
      </div>
    </Section>
  )
}

export default BrowesTabs
