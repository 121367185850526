import { graphql } from "gatsby"
import React from "react"

import BrowesTabsComponent from "./BrowesTabs"

export const fragment = graphql`
  fragment BrowesTabsFragment on WpPage_Flexlayouts_FlexibleLayouts_BrowesTabs {
    sectionHeading
    extraBrandTiles {
      fieldGroupName
        tileLink
        tileBackgroundImage {
          altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 500
                  placeholder: NONE
                )
              }
            }
        }
        overlayedLogo {
          altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 500
                  placeholder: NONE
                )
              }
            }
        }
      }
    brands {
      brand {
        name
        id
        slug
        extendedBrandInfo {
          logo {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 500
                  placeholder: NONE
                )
              }
            }
          }
          smallImage {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 500
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    categories {
      category {
        uri
        link
        extendedCategoryData {
          label
          smallImage {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFBrowesTabs = ({
  sectionHeading,
  extraBrandTiles,
  brands,
  categories,
  config,
}) => (
  <BrowesTabsComponent
    extraBrandTiles={extraBrandTiles}
    sectionHeading={sectionHeading}
    brands={brands}
    categories={categories}
    config={config}
  />
)
