// extracted by mini-css-extract-plugin
export var bgGrey100 = "BrowesTabs-module--bg-grey-100--bd73f";
export var bgGrey150 = "BrowesTabs-module--bg-grey-150--b3377";
export var bgGrey200 = "BrowesTabs-module--bg-grey-200--30040";
export var bgGrey300 = "BrowesTabs-module--bg-grey-300--f92f2";
export var bgGrey400 = "BrowesTabs-module--bg-grey-400--deb1e";
export var bgGrey500 = "BrowesTabs-module--bg-grey-500--8b980";
export var bgGrey600 = "BrowesTabs-module--bg-grey-600--7a32e";
export var bgGrey700 = "BrowesTabs-module--bg-grey-700--e52ac";
export var bgGrey800 = "BrowesTabs-module--bg-grey-800--aa1bb";
export var bgGrey900 = "BrowesTabs-module--bg-grey-900--3c4c2";
export var sectionCls = "BrowesTabs-module--sectionCls--d9e64";
export var textGrey100 = "BrowesTabs-module--text-grey-100--2082f";
export var textGrey150 = "BrowesTabs-module--text-grey-150--0f9cd";
export var textGrey200 = "BrowesTabs-module--text-grey-200--1ea9d";
export var textGrey300 = "BrowesTabs-module--text-grey-300--faa1c";
export var textGrey400 = "BrowesTabs-module--text-grey-400--429dd";
export var textGrey500 = "BrowesTabs-module--text-grey-500--48bb4";
export var textGrey600 = "BrowesTabs-module--text-grey-600--19316";
export var textGrey700 = "BrowesTabs-module--text-grey-700--1996a";
export var textGrey800 = "BrowesTabs-module--text-grey-800--813b1";
export var textGrey900 = "BrowesTabs-module--text-grey-900--47817";