// import { navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

import { Link } from "../../UI/Common"

import {
  boxCls,
  linkCls,
  imgCls,
  labelCls,
} from "./CategoryItem.module.scss"
import { getCatPath } from "../../../utils/slugify";

const CategoryItem = ({ category }) => {
  if (!category) {
    console.warn("No category data.")
    return null
  }

  const {
    extendedCategoryData: { label, smallImage } = {label: null, smallImage: null},
  } = category

  return (
    <Link className={linkCls} link={getCatPath(category, {source:'CategoryItem'})}>
      <div className={boxCls}>
        {smallImage && (
          <GatsbyImage
            className={imgCls}
            style={{ position: "absolute" }}
            image={getImage(smallImage.localFile)}
            alt={smallImage.altText}
          />
        )}
        {label && (
          <div className={labelCls}>{label}</div>
        )}
      </div>
    </Link>
  )
}

export default CategoryItem
