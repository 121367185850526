// extracted by mini-css-extract-plugin
export var bgGrey100 = "CategoryItem-module--bg-grey-100--95ee8";
export var bgGrey150 = "CategoryItem-module--bg-grey-150--57ded";
export var bgGrey200 = "CategoryItem-module--bg-grey-200--d4cc0";
export var bgGrey300 = "CategoryItem-module--bg-grey-300--9daa6";
export var bgGrey400 = "CategoryItem-module--bg-grey-400--358af";
export var bgGrey500 = "CategoryItem-module--bg-grey-500--6a1f9";
export var bgGrey600 = "CategoryItem-module--bg-grey-600--daff3";
export var bgGrey700 = "CategoryItem-module--bg-grey-700--18ba6";
export var bgGrey800 = "CategoryItem-module--bg-grey-800--7ba84";
export var bgGrey900 = "CategoryItem-module--bg-grey-900--719e3";
export var boxCls = "CategoryItem-module--boxCls--ca9a1";
export var imgCls = "CategoryItem-module--imgCls--bbede";
export var labelCls = "CategoryItem-module--labelCls--0039e";
export var linkCls = "CategoryItem-module--linkCls--101e1";
export var textGrey100 = "CategoryItem-module--text-grey-100--3de4b";
export var textGrey150 = "CategoryItem-module--text-grey-150--0634d";
export var textGrey200 = "CategoryItem-module--text-grey-200--0641a";
export var textGrey300 = "CategoryItem-module--text-grey-300--40ef8";
export var textGrey400 = "CategoryItem-module--text-grey-400--02fe2";
export var textGrey500 = "CategoryItem-module--text-grey-500--b0cb2";
export var textGrey600 = "CategoryItem-module--text-grey-600--b3092";
export var textGrey700 = "CategoryItem-module--text-grey-700--1cda5";
export var textGrey800 = "CategoryItem-module--text-grey-800--e6012";
export var textGrey900 = "CategoryItem-module--text-grey-900--28bf8";