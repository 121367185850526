
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Link } from "../../UI/Common"

import { boxCls, imgCls, logoCls } from "./BrandItem.module.scss"

const BrandItem = ({ brand }) => {

  if (!brand) {
    console.warn("No brand data.")
    return null
  }

  const {
    slug,
    extendedBrandInfo: { logo, smallImage } = { logo: null, smallImage: null },
  } = brand

  return (
    <Link link={ slug } >
      <div className={boxCls}>
        {smallImage && (
          <GatsbyImage
            className={imgCls}
            style={{ position: "absolute" }}
            image={getImage(smallImage.localFile)}
            alt={smallImage.altText}
          />
        )}
        {logo && (
          <GatsbyImage
            className={logoCls}
            image={getImage(logo.localFile)}
            alt={logo.altText}
          />
        )}
      </div>
    </Link>
  )
}

export default BrandItem
