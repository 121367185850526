// extracted by mini-css-extract-plugin
export var bgGrey100 = "BrandItem-module--bg-grey-100--c6b57";
export var bgGrey150 = "BrandItem-module--bg-grey-150--82735";
export var bgGrey200 = "BrandItem-module--bg-grey-200--e3bb4";
export var bgGrey300 = "BrandItem-module--bg-grey-300--0af0b";
export var bgGrey400 = "BrandItem-module--bg-grey-400--314fc";
export var bgGrey500 = "BrandItem-module--bg-grey-500--7041f";
export var bgGrey600 = "BrandItem-module--bg-grey-600--7fadb";
export var bgGrey700 = "BrandItem-module--bg-grey-700--b1108";
export var bgGrey800 = "BrandItem-module--bg-grey-800--4bbaf";
export var bgGrey900 = "BrandItem-module--bg-grey-900--4378f";
export var boxCls = "BrandItem-module--boxCls--db98a";
export var imgCls = "BrandItem-module--imgCls--59c2d";
export var logoCls = "BrandItem-module--logoCls--feda0";
export var textGrey100 = "BrandItem-module--text-grey-100--4ca62";
export var textGrey150 = "BrandItem-module--text-grey-150--32844";
export var textGrey200 = "BrandItem-module--text-grey-200--ef1dd";
export var textGrey300 = "BrandItem-module--text-grey-300--05178";
export var textGrey400 = "BrandItem-module--text-grey-400--aba46";
export var textGrey500 = "BrandItem-module--text-grey-500--1bf7d";
export var textGrey600 = "BrandItem-module--text-grey-600--78772";
export var textGrey700 = "BrandItem-module--text-grey-700--1b02c";
export var textGrey800 = "BrandItem-module--text-grey-800--42879";
export var textGrey900 = "BrandItem-module--text-grey-900--a6404";